<template>
  <nav @click.stop="" id="containerMenu">
    <div>
      <ul class="menu-items">
        <li class="notificacoes" @click.prevent="toggleNotificacoes">
          <img src="@/assets/img/topo/notificacao.svg" alt="Notificação de tarefas">
        </li>
        <li>
          <img src="@/assets/img/topo/configuracao.svg" alt="Configurações">
        </li>
        <li>
          <a href="https://ajuda.wapstore.com.br/" target="_blank">
            <img src="@/assets/img/topo/ajuda.svg" alt="Procurar Ajuda">
          </a>
        </li>
        <li class="meuPerfil" @click="togglePerfil">
          <span class="config-usuario">F</span>
          <img src="@/assets/img/topo/arrow.svg" alt="Icone seta" :class="{arrowRotate: caixaFlutuantePerfil}">
        </li>
      </ul>
    </div>
    <Transition> 
      <DefaultHeaderNotifications v-show="caixaFlutuanteNotificacoes" />
    </Transition>
    <Transition>
      <DefaultHeaderProfile v-show="caixaFlutuantePerfil" />
    </Transition>
  </nav>
</template>

<script>
import DefaultHeaderNotifications from './DefaultHeaderNotifications.vue';
import DefaultHeaderProfile from './DefaultHeaderProfile.vue';

export default {
  components: {
    DefaultHeaderNotifications,
    DefaultHeaderProfile,
  },
  data() {
    return {
      caixaFlutuantePerfil: false,
      caixaFlutuanteNotificacoes: false
    }
  },
  watch: {
    caixaFlutuantePerfil(newVal) {
      if(newVal) {
        this.caixaFlutuanteNotificacoes = false;
      }
    },
    caixaFlutuanteNotificacoes(newVal) {
      if(newVal) {
        this.caixaFlutuantePerfil = false;
      }
    } 
  },
  methods: {
    togglePerfil () {
      event.preventDefault();
      this.caixaFlutuantePerfil = !this.caixaFlutuantePerfil;
      document.querySelector('body').addEventListener('click', () => {
        this.caixaFlutuantePerfil = false;
      });
    },
    toggleNotificacoes () {
      this.caixaFlutuanteNotificacoes = !this.caixaFlutuanteNotificacoes;

      if (this.caixaFlutuanteNotificacoes) {
        this.$store.state.notificationModule.notifications.forEach(notification => {
          this.$store.dispatch('postViewNotification', notification.id);
        });
        document.querySelector('body').addEventListener('click', this.toggleNotificacoes);
      } else {
        document.querySelector('body').removeEventListener('click', this.toggleNotificacoes);
      }
    },
  },
}
</script>

<style scoped>
.menu-items {
  display: flex;
  gap: 25px;
  align-items: center ;
}
.menu-items li {
  cursor: pointer;
}
.menu-items li img {
  transition: 0.5s;
}
.config-usuario {
  border: 2px solid #5c5c5c;
  color: #5C5C5C;
  font-size: 14px;
  background: #D9D9D9;
  border-radius: 20px;
  width: 26px;
  height: 26px;
  display: inline-flex;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
}

#containerMenu {
  position: relative;
}
.arrowRotate {
  transform: rotate(180deg);
  transition: 0.5s;
}
</style>
<template>
  <div class="caixaFlutuante-notificacoes">
    <div class="notificacoes">
      <div 
        v-for="({ author, texto, createdAt }, index) in projectsNotifications" 
        :key="`notification${index}`" 
        class="notificacao-item"
      >
        <div class="notificacao-item-fotoUser">
          <img src="@/assets/img/topo/notificacao/user-fake.jpg" alt="User fake">
        </div>
        <div class="notificacao-item-info">
          <div class="notificacao-item-infoUser">
            <div>
              <span class="nomeUsuario">
                {{ author.nome }}
              </span>
              <span class="setor">
                {{ author.responsibility }}
              </span>
            </div>
            <div>
              <span class="dataNotificacao">
                {{ createdAt | formatDate }}
              </span>
            </div>
          </div>
          <div>
            <p class="textoNotificacao">
              {{ texto }}
            </p>
          </div>
        </div>
      </div>

      <div v-if="!projectsNotifications.length" class="notifications__nothing">
        Nenhuma notificação no momento.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      intervalTime: 0
    }
  },  
  methods: {
    fetchProjectsNotificationsById() {
      this.$store.dispatch('fetchProjectsNotificationsById', this.$store.state.projectModule.currentProject.id);
    }
  },
  filters: {
    formatDate(timedate) {
      const [ date ] = timedate.split(' ');
      const [ year, month, day ] = date.split('-');

      return `${day}/${month}/${year}`;
    }
  },
  computed: {
    projectsNotifications() {
      return this.$store.state.notificationModule.notifications;
    }
  },  
  created() {
    this.fetchProjectsNotificationsById();
    this.intervalTime = setInterval(() => {
      this.fetchProjectsNotificationsById();
    }, 60000); //60000 = 1 minuto;
  },
  destroyed() {
    clearInterval(this.intervalTime);
  }
}
</script>

<style scoped>
.notifications__nothing {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 12px;
  color: #1C1C1C;
}
.caixaFlutuante-notificacoes {
  position: absolute;
  bottom: 0;
  top: 57px;
  right: 139px;
  min-width: 365px;
  z-index: 20;
}
.caixaFlutuante-notificacoes::before {
  content: "";
  position: absolute;
  top: -14px;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
  z-index: 1;
}
.notificacoes {
  background-color: #FFFF;
  width: 100%;
  max-height: 311px;
  padding-top: 10px;
  box-shadow: 0px 4px 30px 0px rgba(101,101,101,0.25);
  overflow-y: auto;
}
.notificacoes::-webkit-scrollbar {
  width: 5px;
}
 
.notificacoes::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
}
 
.notificacoes::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  outline: 1px solid #D9D9D9;
  border-radius: 30px;
}
.notificacao-item, .notificacao-item-infoUser {
  display: flex;
}
.notificacao-item:not(:last-child) {
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 20px;
}
.notificacao-item-infoUser {
  justify-content: space-between;
  margin-bottom: 14px;
}
.notificacao-item-fotoUser {
  width: 74px;
  justify-content: center;
  display: flex;
  justify-self: center;
}
.notificacao-item-fotoUser img {
  width: 46px;
  height: 46px;
}
.notificacao-item-info {
  max-width: 266px;
  width: 100%;
}
.nomeUsuario {
  font-size: 13px;
  font-weight: bold;
  color: #5c5c5c;
  display: block;
}
.setor {
  font-size: 12px;
  color: #5c5c5c;
}
.dataNotificacao {
  font-size: 10px;
  color: #9A9A9A;
}
.textoNotificacao {
  font-size: 12px;
  margin-bottom: 12px;
  color: #5c5c5c;
}
.notificacao-item-fotoUser {
  position: relative;
}
.notificacao-item-fotoUser::before {
  content: '';
  background: #E85128;
  top: 3px;
  right: 14px;
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 20px;
}
</style>
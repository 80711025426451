<template>
  <div class="caixaFlutuante-perfil">
    <ul>
      <li>
        <img src="@/assets/img/topo/meu-perfil.svg" alt="Meu perfil">
         Meu perfil
      </li>
      <li>
        <router-link to="/nova-senha">
          <img src="@/assets/img/topo/trocar-senha.svg" alt="Trocar senha"> 
          Trocar senha
        </router-link>
      </li>
      <li><img src="@/assets/img/topo/meus-acessos.svg" alt="Meus acessos"> Meus acessos</li>
      <li @click="handleLogoutUser">
        <img src="@/assets/img/topo/sair.svg" alt="Sair"> 
        Sair
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Perfil',
  methods: {
    async handleLogoutUser() {
      await this.$store.dispatch('logoutUser');
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>
.caixaFlutuante-perfil {
  position: absolute;
  bottom: 0;
  top: 57px;
  right: 0px;
  min-width: 194px;
  min-height: 174px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(101,101,101,0.25);
  z-index: 20px;
}
.caixaFlutuante-perfil::before {
  content: "";
  position: absolute;
  top: -14px;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
  z-index: 1;
}
.caixaFlutuante-perfil li {
  padding: 10px 23px; 
  font-size: 14px;
  color: #0e0e0e;
  cursor: pointer;
}
.caixaFlutuante-perfil li a {
  display: block;
}
.caixaFlutuante-perfil li img {
  margin-right: 14px;
}
.caixaFlutuante-perfil li:hover {
  background: #E9E9E9;
  z-index: 10;
}
</style>
<template>
  <div id="layout-default">

    <div class="fundoEscuro" v-if="fundoEscuro" />
    <DefaultHeader />
      <router-view />
    <DefaultFooter />
  </div>
</template>

<script>
export default {
  name: 'LayoutDefault',
  components: {
    DefaultHeader: () => import(/* webpackChunkName: "LayoutDefault" */ '@/components/default/DefaultHeader.vue'),
    DefaultFooter: () => import(/* webpackChunkName: "DefaultFooter" */ '@/components/default/DefaultFooter.vue'),
  },
  computed: {
    fundoEscuro() {
      return this.$store.state.fundoEscuro;
    }
  },
}
</script>

<style scoped>

</style>
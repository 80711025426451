<template>
  <header>
    <div class="row">
      <div class="column small-12">
        <div  class="container-topoMenu">
          <div>
            <router-link to="/painel">
              <img src="@/assets/img/topo/logo.svg" alt="Wap Store implantação">
            </router-link>
          </div>
          <div>
            <DefaultHeaderMenu />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import DefaultHeaderMenu from './DefaultHeaderMenu.vue';
export default {
  components: {
    DefaultHeaderMenu,
  },
}
</script>

<style scoped>
header {
  background-color: #f5f5f5;
  min-height: 108px;
  margin-bottom: 12px;
}
.container-topoMenu {
  min-height: 108px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>